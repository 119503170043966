<template>
  <div>
    <!-- 获取需要展示的数据 -->
      <GetUrlsForShow mainUrl="/v1/basic_data_manager/"/>
      <a-divider style="border-color: #7cb305" dashed />
      <a-typography-text code>当前选中类型：{{$store.state.show_data.getSelectedUrlName['selectedUrl']}}</a-typography-text>
      <a-divider style="border-color: #7cb305" dashed />
      <!-- 读取获取的数据，显示在表格中 -->
      <ShowTableData />
  </div>
</template>

<script>
import ShowTableData from '@/components/show_data/ShowTableData.vue'
import GetUrlsForShow from '@/components/show_data/GetUrlsForShow.vue'
import {defineComponent} from 'vue'
export default defineComponent({
    components :{
        ShowTableData,
        GetUrlsForShow
    }
})
</script>

<style>

</style>