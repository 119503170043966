<template>
  <a-table :columns="$store.state.show_data.getColumns['columns']" :data-source="$store.state.show_data.getData['data']" :scroll="{ x: 1500, y: 300 }"
  :pagination="paginationState"
  v-if="$store.state.show_data.getSelectedUrlName['selectedUrl'] !== ''"
  >
    <template #action="{record}">
      <a>{{record['name']}}</a>
    </template>
    <template #id="{record}">
      <a>{{record['id']}}</a>
    </template>
    <template #name="{record}">
      <a>{{record['name']}}</a>
    </template>
    <template #details>
      <a>详情</a>
    </template>
    <template #update>
      <a>更新</a>
    </template>
  </a-table>
</template>
<script>
import { defineComponent, onMounted, reactive, watch, ref } from 'vue';
import {request} from '@/utils/request.js';
import {useStore} from 'vuex';
import {message} from 'ant-design-vue';
export default defineComponent({
  setup() {
    const pageUrl = ref('')
     const paginationState = reactive({
      defaultCurrent: 1,
      pageSize: 10,
      total: 64,
      onChange: current => getPageData(current),
    });
    const store = useStore()
    const getPageData = (current_page) => {
      const certainUrl = pageUrl.value.split('page')[0] + 'page=' + current_page
      request({
        // 获取全局属性的方法
        url: certainUrl,
        method: "get",
        headers: {
          Authorization: "JWT " + store.state.user_login_auth.user["token"],
        },
      }).then((response) => {
      // get data
      var allData = []
      for (var r in response.data.results) {
        var data = {}
        data['id'] = response.data.results[r]['url'].split('/').reverse()[1]
        console.log(response.data.results[r]['url'].split('/'))
        for (var dt in response.data.results[r]) {
          data[dt] = response.data.results[r][dt]
        }
        allData.push(data)
      }
      store.commit(
          {
          type: "show_data/saveData",
        payload: {'data': allData}
        }
        )
      }).catch((e) => {
        message.error(e+'获取后端数据失败！')
      }
      )
    }
    const getShowColumnsData = (targetUrl) => {
      request({
        // 获取全局属性的方法
        url: targetUrl,
        method: "get",
        headers: {
          Authorization: "JWT " + store.state.user_login_auth.user["token"],
        },
      }).then((response) => {
        console.log(response.data)
        // get columns
        var columns = []
        columns.push({
          title: 'ID',
                key: 'id',
                fixed: 'left',
                slots: {
                    customRender: 'id',
                },
        })
        for (var ck in response.data.results[0]) {
          if (ck === 'name') {
            columns.push({
            title: '名称',
                key: 'name',
                fixed: 'left',
                slots: {
                    customRender: 'name',
                },
        })
          }
          if (ck !== 'url' && ck !=='name') {
            columns.push({
                title: ck,
                dataIndex: ck,
                key: ck
            })
          }
        }
        columns.push({
          title: '详情',
                key: 'details',
                fixed: 'right',
                slots: {
                    customRender: 'details',
                },
        })
        columns.push({
          title: '更新',
                key: 'update',
                fixed: 'right',
                slots: {
                    customRender: 'update',
                },
        })
        store.commit(
          {
          type: "show_data/saveColumns",
        payload: {'columns': columns}
        }
        )
      // get data
      var allData = []
      for (var r in response.data.results) {
        var data = {}
        data['id'] = response.data.results[r]['url'].split('/').reverse()[1]
        for (var dt in response.data.results[r]) {
          data[dt] = response.data.results[r][dt]
        }
        allData.push(data)
      }
      store.commit(
          {
          type: "show_data/saveData",
        payload: {'data': allData}
        }
        )
      // pagination
      paginationState.total = response.data.count
      if (response.data.next) {
        pageUrl.value = response.data.next
      }
      }).catch((e) => {
        message.error(e+'获取后端数据失败！')
      }
      )
    }
  watch(() => store.state.show_data.getSelectedUrlName['selectedUrl'], function() {
    getShowColumnsData(store.state.show_data.getAllUrls['showUrls'][store.state.show_data.getSelectedUrlName['selectedUrl']])
  })
  onMounted(() => {
    if (store.state.show_data.getSelectedUrlName['selectedUrl']) {
      getShowColumnsData(store.state.show_data.getAllUrls['showUrls'][store.state.show_data.getSelectedUrlName['selectedUrl']])
    }
  })
    return {paginationState, getPageData}
  },
});
</script>