<template>
  <a-select label-in-value v-model:value="selectedUrl" style="width: 300px" @change="handleChange">
    <a-select-option v-for="k in urlType" :value="k" :key="k">{{ k }}</a-select-option>
  </a-select>
</template>
<script>
// 选择具体路由，获取需要展示的信息
import { defineComponent, onMounted, getCurrentInstance, ref } from 'vue';
import {message} from 'ant-design-vue'
import {request} from '@/utils/request.js'
import {useStore} from 'vuex'
export default defineComponent({
  props: ['mainUrl'],
  setup(props) {
    const store = useStore()
    // 获取全局属性的方法
    const properties = getCurrentInstance()
    const baseUrl = store.state.alternate_server.server['alternate'] ? properties.appContext.config.globalProperties.$alternateServerUrl : properties.appContext.config.globalProperties.$mainServerUrl
    // 获取首页的链接信息
    const showUrls = ref({'请选择下列选项': ''})
    const urlType = ref(['请选择下列选项'])
    const selectedUrl = ref('请选择下列选项')
    onMounted(() => {
      request({
        // 获取全局属性的方法
        url: baseUrl + props.mainUrl,
        method: "get",
        headers: {
          Authorization: "JWT " + store.state.user_login_auth.user["token"],
        },
      }).then((response) => {
        for (var k in response.data) {
            showUrls.value[k] = baseUrl + props.mainUrl + k + '/'
            urlType.value.push(k)
        }
        store.commit(
        {
          type: "show_data/saveGetAllUrlsData",
        payload: {'showUrls': showUrls}
        }
      )
      }).catch(() => {
        message.error('获取数据处理方法列表失败！')
      }
      );
    })
    const handleChange = value => {
      store.commit(
        {
          type: "show_data/savegetSelectedUrlNameData",
        payload: {'selectedUrl': value.value}
        }
      )
    };

    return {
      showUrls,
      urlType,
      handleChange,
      selectedUrl
    };
  },
});
</script>